import _class from "@compone/class";
import _event from "@compone/event";
import _client from "utilise/client";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

const classed = _class,
      event = _event,
      client = _client,
      noop = () => {},
      HTMLElement = client && window.HTMLElement || class {},
      registry = client && window.customElements || {};

exports = function define(name, component) {
  if (arguments.length == 1) {
    component = name, name = `anon-${registry.anon++}`;
  }

  if (component.hasOwnProperty("wrapper")) return component.wrapper;
  if (!name.includes("-")) return;
  if (!client) return wrap(classed(component));
  let wrapped = registry.get(name);

  if (wrapped) {
    if (wrapped.class == classed(component)) return wrapped;
    wrapped.class = classed(component);
    const instances = Array.from(document.querySelectorAll(name));
    instances.map(node => {
      // TODO: should probably await these
      node.disconnectedCallback();
      node.methods.map(method => {
        delete node[method];
      });
      node.connectedCallback();
    });
  } else {
    registry.define(name, wrapped = wrap(classed(component)));
  }

  return wrapped;
};

const wrap = component => {
  if (!component.hasOwnProperty("wrapper")) component.wrapper = class extends HTMLElement {
    constructor() {
      super();
      event(this || _global);
      (this || _global).ready = this.once("ready");
      (this || _global).state = (this || _global).state || {};
    }

    connectedCallback() {
      const {
        prototype
      } = component.wrapper.class;
      (this || _global).methods = Object.getOwnPropertyNames(prototype).filter(method => !(method in disallowed)).map(method => ((this || _global)[method] = prototype[method].bind(this || _global), method));
      return Promise.resolve(((this || _global).connected || noop).call(this || _global, this || _global, (this || _global).state)).then(d => {
        this.emit("ready");
        return this.render();
      });
    }

    render() {
      const {
        prototype
      } = component.wrapper.class;
      return (this || _global).pending = (this || _global).pending || (this || _global).ready.then(() => {
        delete (this || _global).pending;
        return prototype.render.call(this || _global, this || _global, (this || _global).state);
      });
    }

    disconnectedCallback() {
      ((this || _global).disconnected || noop).call(this || _global, this || _global, (this || _global).state);
      this.dispatchEvent(new CustomEvent("disconnected"));
      (this || _global).initialised = false;
    }

    get(sel) {
      return this.querySelector(sel);
    }

  };
  component.wrapper.class = component;
  return component.wrapper;
};

const disallowed = {
  length: 1,
  prototype: 1,
  name: 1,
  render: 1
};
registry.anon = registry.anon || 1;
export default exports;